import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Fade from "react-reveal/Fade"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Tomorrow = () => {
  return (
    <>
      <Layout>
        <Seo title="Tomorrow's challenges demand new approaches: Smart soft technology integration in medical devices " />
        <Fade top>
          <div className="py-10 lg:py-12 bg-dusk"></div>
        </Fade>
        <div className="py-24">
          <div className="xl:pr-96">
            <div className="container mx-auto text-dusk">
              <div>
                <div className="text-5xl font-firs-medium">
                  Tomorrow's challenges demand new approaches:
                  {/* <div> Smart Textiles</div> */}
                </div>
                <div className="text-2xl mt-4">
                  Smart soft technology integration in medical devices
                </div>
                {/* <div className="mt-4">16th October 2021</div> */}
              </div>

              <div className="mb-4 pt-10">
                <div>
                  <StaticImage
                    alt="Softmatter"
                    src="../../images/news/tomorrow/RPM.jpg"
                  />
                </div>
                <div className="my-4">
                  Making accessibility and affordability core to medtech product
                  innovation in RPM, pain, sleep and respiratory care.
                </div>
                <div className="mb-4">
                  Change is often the result of disruption. If the COVID-19
                  pandemic has taught the healthcare community anything, it is
                  that medical professionals must re-assess what works — and
                  what does not — within the greater healthcare network.
                </div>
                <div className="mb-4">
                  While doctors, nurses and those who support them are working
                  diligently to respond to public health threats, it is evident
                  that much within the system requires revision. From COVID-19
                  and other infectious diseases to the rising toll of chronic
                  illness, the world is facing an onslaught of health
                  challenges. The health-care system and its personnel are
                  overburdened. Cost is still a problem, and access to
                  healthcare by the wider group of society is limited.
                </div>
                <div className="mb-4">
                  That's why we at Softmatter are aware that enabling people to
                  live more empowered lives through better health cannot happen
                  without innovation and accessibility. From every step of the
                  innovation and development process, to how we manufacture and
                  distribute them, the path to this goal begins by developing
                  life-changing technologies and products for accessibility and
                  affordability.
                </div>
                <div className="mb-4">
                  Fortunately, advancements in technology are helping healthcare
                  workers continue to provide exceptional care while
                  simultaneously allowing them to adapt to new threats.
                  Intuitive technological developments are helping those who
                  support the medical community to create MedTech solutions and
                  other medical devices that address the needs of vulnerable
                  communities and expand public access to care.
                </div>
                <div className="mb-4">
                  This mindset already has allowed Softmatter to make meaningful
                  progress in the type of solutions we have developed that has
                  resulted in considerable gains: wearable systems for patient
                  biometric analysis, thermal comfort and recovery technology,
                  and more- are all examples of tools that have improved the
                  day-to-day lives of healthcare professionals and their
                  patients at affordable rates.
                </div>

                <div className="mb-4 pt-5">
                  <div className="font-firs-semibold mb-3">
                    The Pragmatic Benefits of Smart Soft Technology
                  </div>
                  <div className="mb-4">
                    Smart soft systems such as wearable textile devices are more
                    than a medical improvement. In fact, they are a pragmatic
                    solution to longstanding production and distribution
                    concerns within the healthcare system. The technology is
                    intuitively designed with affordability and reproducibility
                    in mind: the end-goal is to manufacture and deliver the
                    maximum number of products to the greatest number of
                    patients possible. It is also fast and easy to economically
                    reproduce without sacrificing patient comfortability or
                    product efficacy. As a result, smart soft medical devices
                    benefit from both production and distribution cost-savings.
                  </div>
                  <div className="mb-4">
                    Doctors and patients alike will be pleased to know that
                    wearable technology developed through smart textiles also
                    provides exceptional biometric readings and real-time data
                    analysis. These readings can help patients with potentially
                    life-threatening conditions receive immediate care from
                    experienced professionals.
                  </div>
                </div>
                <div className="mb-4 pt-5">
                  <div className="font-firs-semibold mb-3">
                    Develop effective smart medical device with Softmatter
                  </div>
                  <div className="mb-4">
                    Softmatter is a smart textile technology manufacturer and
                    product development company passionate about creating
                    impactful medical devices that prioritize quality and
                    user-centered design by integrating smart soft features. We
                    build textile-based wearables that are simple and
                    inexpensive to produce, comfortable and unobtrusive to
                    patients, and provide powerful biometrics for doctors who
                    want accurate, real-time data.
                  </div>

                  <div className="mb-4">
                    By partnering with our team of skilled engineering experts,
                    MedTech companies can:
                    <ul className="pl-10 mt-2">
                      <li className="list-disc mb-2">
                        Meet FDA-I & II medical device regulations while
                        accelerating time to market
                      </li>
                      <li className="list-disc mb-2">
                        Leverage collaborative expertise from experienced
                        industry professionals
                      </li>
                      <li className="list-disc mb-2">
                        Design and build from concept to production
                      </li>
                    </ul>
                  </div>
                  <div className="mb-4">
                    Our highly-skilled engineers and developers deliver a
                    competitive difference with our on-site manufacturing
                    facilities commit to ISO 9001 and 13485 standards.
                  </div>

                  <div className="mb-4">
                    We recognize that medical device company’s primary focus is
                    safety, precision, and fully functional life of their
                    product.
                  </div>
                  <div className="mb-2">We enable solutions in:</div>
                  <div className="mb-4">
                    <b> Advanced materials:</b> Elevated performance textile and
                    surface modifications for devices (Water repellency and
                    other resistive properties, enhanced grip for performance
                    application, and silicon flocking for elevated hand feel).
                    Selection of material that can conform to biocompatibility
                    standards with full traceability.
                  </div>
                  <div className="mb-4">
                    <b> Device finishes: </b>replacing hard surfaces with soft
                    forms for aesthetic product designs that complement medical
                    needs and provide superior soft-touch feel. These include
                    complex knit (flat, circular, warp) for zonal compression
                    solutions, injection molding for better grip and impact
                    protection, narrow width fabric strap solutions for zero
                    skin markings and much more.
                  </div>
                  <div className="mb-4">
                    <b> Smart features:</b> Portfolio of patented technologies
                    including textile sensing technology that allows for
                    real-time biometric data tracking for a chronic illness,
                    age, or sport. Device miniaturization, pressure sensing in
                    wheelchairs and beds, amongst other healthcare needs. We
                    bring the functionality of electronics together with the
                    comfort and adaptability of textiles.
                  </div>
                  <div className="mb-4">
                    <b> Actuators & electrodes:</b> Our next-to-skin
                    integrations of actuators and EC-12 compliant sensor
                    electrodes offers sense stimuli from the environment and
                    also reacts to them. These include phase change materials,
                    shape memory materials, and touch textile applications.
                  </div>
                </div>
                <div className="mb-4 pt-5">
                  <div className="font-firs-semibold mb-3">
                    Sleep and Respiratory Devices
                  </div>
                  <div className="mb-4">
                    Individuals with a pattern of sleep and respiratory issues
                    often suffer from complex and difficult-to-treat medical
                    histories due to a lack of accurate biometric data.
                    Fortunately, wearable technology serves as a potential
                    answer for these patients. Remote patient monitoring (RPM)
                    allows physicians to understand and treat sleep-related
                    disorders using unobtrusive technology that can be worn
                    comfortably during sleep.
                  </div>

                  <div className="mb-4">
                    We are constantly developing products that works for the
                    user and for the patient’s experience of health technology.
                    For devices that are worn for extended sessions as with
                    sleep and respiratory care, comfort is paramount to ensure
                    adherence and long-term use. Textiles and soft form factors
                    allow for the creation of entirely new comfortable
                    next-to-skin products in the healthcare & wellness space.
                  </div>
                </div>
                <div className="mb-4 pt-5">
                  <div className="font-firs-semibold mb-3">
                    Softmatter Smart EEG Headband
                  </div>
                  <div className="mb-4">
                    Driven by seamless integration, the head worn wearable that
                    comprises of engineered knitting and sensors merged into a
                    single solution, for data driven insights. The solution is
                    also designed to be skin friendly and not hamper comfort
                    when sleeping.
                  </div>
                </div>
                <div className="mb-4 pt-5">
                  <div className="font-firs-semibold mb-3">
                    Softmatter Remote Monitoring Garment
                  </div>
                  <div className="mb-4">
                    Explores our capabilities around tracking respiratory
                    metrics from afar. Here, we leverage ECG sensing that is
                    integrated into an apparel form factor in a non-obtrusive
                    manner, to pull signals from your heart. This concept has
                    been created to assist care givers and patients, in
                    monitoring real-time insights.
                  </div>
                </div>
                <div className="mb-4 pt-5">
                  <div className="font-firs-semibold mb-3">
                    Softmatter Device Finishes
                  </div>
                  <div className="mb-4">
                    Beyond the pragmatic implications of wearable technology and
                    smart textiles, the platforms also provide benefits for
                    patients experiencing sleep loss and respiratory
                    difficulties through smart fabric finishes. Constructions
                    may come in the form of zonal compression, knit to shape,
                    zero seams, and inlay technology in some use-cases.
                  </div>
                  <div className="mb-4">
                    For patients who battle sleep apnea everyday - we leverage
                    our knitting capabilities to create solutions that enhances
                    the patient’s comfort levels, when dealing with respiratory
                    related ailments. We create fabric finishes for CPAP devices
                    for comfort and zero skin marking, without compromising
                    functionality. Our mask harness highlights our capabilities
                    in creating solutions that may not necessarily use
                    electronics, but instead focus on the need for long-lasting
                    medical components that are easy to reuse as they are
                    washable, breathable and offer convenience.
                  </div>
                </div>
                <div className="mb-4 pt-5">
                  <div className="font-firs-semibold mb-3">
                    Pain and muscle recovery solutions
                  </div>
                  <div className="mb-4">
                    The intuitive design of wearable textiles may benefit
                    another at-risk community within the healthcare system:
                    patients who experience long term pain. Traditionally,
                    individuals experiencing frequent pain and muscle injury are
                    prescribed addictive and potentially life-inhibiting
                    medications. Thermal therapy solutions may provide an
                    alternative relief to such situations. The heating
                    technology comprised of insulated conductive wires, fosters
                    thermal pain relief to block off pain signals before they
                    trigger a neurological response.
                  </div>
                  <div className="mb-4">
                    Our technologies offer varied configurations for pain
                    management and muscle recovery needs. Systems may be built
                    around compression garments with built in sensor technology
                    for updates on health data, such as muscle activity, active
                    and resting heart rate, breathing, or temperature levels.
                    Alternatively, we are also able to explore Haptics, TENS and
                    EMS technology to support pain management needs.
                  </div>
                  <div className="mb-4">
                    The implications of products such as these are numerous:
                    individuals could utilize compression medical solutions
                    during physical therapy, sleep, exercise and light everyday
                    use.
                  </div>
                </div>
                <div className="mb-4 pt-5">
                  <div className="font-firs-semibold mb-3">
                    Building Engineered Solutions:
                  </div>
                  <div className="mb-4">
                    New technology must always be integrated into the larger
                    healthcare system for maximum efficiency. Fortunately,
                    wearable technology lends itself well to rapid integration
                    by both patients and physicians: convenience, ease-of-use,
                    simple production and medical-grade benefits make the case
                    for immediate introduction into an overburdened healthcare
                    system that needs direct support.
                  </div>
                  <div className="mb-4">
                    It's critical to select a product development partner that
                    will help you satisfy FDA regulations while still that time
                    to market in this heavily regulated industry. Do you want to
                    make technology that prioritizes patient-centered
                    architecture and quality? Connect with us to get your
                    medical device production underway.
                  </div>
                  <div className="mb-4">
                    Softmatter is a leading global wearable technology company
                    dedicated to solving the toughest smart soft engineering
                    challenges. We enable our customers to interpret the world
                    around us by intelligently bridging the smart with the soft
                    with unmatched technologies that empower and connect.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Tomorrow
